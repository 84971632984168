import { Injectable } from '@angular/core';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import {
  ClientDashboard,
  ClientDashboardCreateProperties,
  DashboardManageEndpoints,
  DashboardProperties,
} from '@memberspot/shared/model/memberarea';

@Injectable({
  providedIn: 'root',
})
export class DashboardBackendService {
  constructor(private http: HttpBackendService) {}

  getAllDashboards(schoolId: string) {
    return this.http.generic<ClientDashboard[]>(
      DashboardManageEndpoints.GET_ALL(schoolId),
    );
  }

  addDashboard(schoolId: string, properties: ClientDashboardCreateProperties) {
    return this.http.generic<ClientDashboard>(
      DashboardManageEndpoints.CREATE_DASHBOARD(schoolId, properties),
    );
  }

  deleteDashboard(schoolId: string, dashboardId: string) {
    return this.http.generic(
      DashboardManageEndpoints.DELETE_DASHBOARD(schoolId, dashboardId),
    );
  }

  duplicateDashboard(
    schoolId: string,
    dashboardId: string,
    targetSchoolId?: string,
  ) {
    return this.http.generic(
      DashboardManageEndpoints.DUPLICATE_DASHBOARD(
        schoolId,
        dashboardId,
        targetSchoolId,
      ),
    );
  }

  updateDashboardProperties(
    schoolId: string,
    dashboardId: string,
    properties: DashboardProperties,
  ) {
    return this.http.generic<ClientDashboard>(
      DashboardManageEndpoints.UPDATE_PROPERTIES({
        schoolId,
        dashboardId,
        properties,
      }),
    );
  }
}
