import { BackendMethod } from '@memberspot/shared/model/backend';

import {
  CreateDashboardDto,
  UpdateDashboardPropertiesDto,
} from './dto/dashboard-manage';

export const DashboardManageEndpoints = {
  GET_ALL: (schoolId: string) => ({
    url: `/client-dashboard/manage/${schoolId}`,
    method: BackendMethod.GET,
  }),
  GET_DASHBOARD: (schoolId: string, dashboardId: string) => ({
    url: `/client-dashboard/manage/${schoolId}/${dashboardId}`,
    method: BackendMethod.GET,
  }),
  CREATE_DASHBOARD: (schoolId: string, data: CreateDashboardDto) => ({
    url: `/client-dashboard/manage/${schoolId}`,
    method: BackendMethod.POST,
    data,
  }),
  DELETE_DASHBOARD: (schoolId: string, dashboardId: string) => ({
    url: `/client-dashboard/manage/${schoolId}/${dashboardId}`,
    method: BackendMethod.DELETE,
  }),
  DUPLICATE_DASHBOARD: (
    schoolId: string,
    dashboardId: string,
    targetSchoolId?: string,
  ) => ({
    url: targetSchoolId
      ? `/client-dashboard/manage/duplicate/${schoolId}/${dashboardId}/${targetSchoolId}`
      : `/client-dashboard/manage/duplicate/${schoolId}/${dashboardId}`,
    method: BackendMethod.POST,
  }),
  UPDATE_PROPERTIES: (data: UpdateDashboardPropertiesDto) => ({
    url: `/client-dashboard/manage/update-properties/${data.schoolId}/${data.dashboardId}`,
    method: BackendMethod.PATCH,
    data,
  }),
};
