import { Injectable } from '@angular/core';
import { ClientDashboardStore } from '@memberspot/frontend/shared/data-access/client-dashboard';
import {
  ClientDashboard,
  ClientDashboardCreateProperties,
  DashboardProperties,
} from '@memberspot/shared/model/memberarea';
import { tap } from 'rxjs';

import { DashboardBackendService } from './dashboard-backend.service';

@Injectable({
  providedIn: 'root',
})
export class ClientDashboardManageService {
  constructor(
    private dashboardBackendService: DashboardBackendService,
    private store: ClientDashboardStore,
  ) {}

  sync(schoolId: string) {
    this.store.setLoading(true);

    return this.dashboardBackendService.getAllDashboards(schoolId).pipe(
      tap((dashboards) => {
        this.store.set(dashboards);
        this.store.setLoading(false);
      }),
    );
  }

  updateDashboard(id: string, upt: Partial<ClientDashboard>) {
    this.store.update(id, (state) => ({ ...state, ...upt }));
  }

  addDashboard(schoolId: string, properties: ClientDashboardCreateProperties) {
    return this.dashboardBackendService
      .addDashboard(schoolId, properties)
      .pipe(tap((dashboard) => this.store.upsert(dashboard.id, dashboard)));
  }

  updateDashboardProperties(
    schoolId: string,
    dashboardId: string,
    properties: DashboardProperties,
  ) {
    return this.dashboardBackendService
      .updateDashboardProperties(schoolId, dashboardId, properties)
      .pipe(tap((dashboard) => this.store.upsert(dashboard.id, dashboard)));
  }

  deleteDashboard(schoolId: string, dashboardId: string) {
    return this.dashboardBackendService
      .deleteDashboard(schoolId, dashboardId)
      .pipe(tap(() => this.store.remove(dashboardId)));
  }

  duplicateDashboard(
    schoolId: string,
    dashboardId: string,
    targetSchoolId?: string,
  ) {
    return this.dashboardBackendService
      .duplicateDashboard(schoolId, dashboardId, targetSchoolId)
      .pipe(
        tap((dashboard) => {
          if (targetSchoolId === schoolId) {
            this.store.upsert(dashboard.id, dashboard);
          }
        }),
      );
  }

  upsertDashboardInStore(dashboard: ClientDashboard) {
    this.store.upsert(dashboard.id, dashboard);
  }
}
